<template>
  <div>
    <div v-if="isMobile && !isSafari" ref="InstallApp" class="install-app d-block d-md-none relative transition-all duration-300" :class="showInstallApp ? 'visible opacity-100 open' : 'invisible opacity-0'">
      <div class="relative flex items-center p-2 bg-pri text-white border-b-2 border-t-0 border-l-0 border-r-0 border-solid border-gray-200" @click="onClickOpenApp">
        <div class="absolute top-1 right-2 block cursor-pointer" @click.stop="handleCloseInstallApp">
          <i class="el-icon-error text-lg" />
        </div>
        <div class="flex items-center p-1 bg-white rounded-lg">
          <img class="w-10 h-10" src="../src/assets/images/hodo-pro.png" alt="HODO Pro" />
        </div>
        <div class="pl-2 flex-1">
          <div class="font-bold text-2xl">HODO PRO</div>
          <div class="text-xs">Cài đặt và trải nghiệm ứng dụng toàn diện dành cho Bác sĩ</div>
        </div>
      </div>
    </div>
    <!-- <ChatCpn /> -->
    <!-- set progressbar -->
    <!-- <vue-progress-bar></vue-progress-bar> -->
    <EMRVersionList />
    <component :is="layout">
      <router-view></router-view>
    </component>
    <div class="modal fade" id="toast__box" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal--border">
          <div class="modal-header">
            <h5 class="modal-title">Thông Báo!!!</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div id="toast__message"></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <audio ref="noti_sound" id="noti_sound" preload="auto" allow="autoplay">
      <source src="../src/assets/audio/iphone_notification.mp3" type="audio/mp3" />
    </audio>

    <!-- <ProgressBox v-if="progress.show" :value="progress.value" :name="progress.name" :title="progress.title" /> -->
    <MultiUploadProgressBox v-if="$multiUploading && $multiUploading.length" />
  </div>
</template>

<script>
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app'
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics'

// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/messaging'
import { mapActions, mapState } from 'vuex'
// import {mixinPushNotification} from './utils/mixinPushNotification'
import { HodoConfig } from '../config'
import appUtils from './utils/appUtils'
import { mixinNotification } from './utils/mixinNotification'

import Echo from 'laravel-echo'
import ChatCpn from './components/Chats/ChatCpn.vue'
import EMRVersionList from './components/ElectronicMedicalRecords/EMRVersionList.vue'
import MultiUploadProgressBox from './components/Ultis/MultiUploadProgressBox.vue'
import ProgressBox from './components/Ultis/ProgressBox.vue'
import { APP_MODE } from './utils/constants'
// import M from 'materialize-css'
window.io = require('socket.io-client')

const defaultLayout = 'user'

export default {
  // mixins: [mixinPushNotification],
  name: 'App',
  mixins: [mixinNotification],
  components: { ProgressBox, ChatCpn, MultiUploadProgressBox, EMRVersionList },
  computed: {
    ...mapState({
      error_message: state => state.app.error_message,
      success_message: state => state.app.success_message
    }),
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    },
    isMobile () {
      return appUtils.checkIsMobile()
    },
    isSafari () {
      return appUtils.checkIsSafari()
    }
  },
  data () {
    return {
      progress: {
        show: false,
        value: 0,
        name: '',
        title: ''
      },
      s3Files: [],
      showInstallApp: false
    }
  },
  destroyed () {
    if (window.client) {
      window.client.disconnect()
    }
  },
  async created () {
    this.handleOpenApp()

    window.$router = this.$router
    window.$progress = this.$Progress
    if (appUtils.getLocalUser()) {
      this.$user = appUtils.getLocalUser()
      this.setLocalUserInfo()
    }
    if (appUtils.getClinicId()) {
      this.$clinicId = parseInt(appUtils.getClinicId())
    }
    if (appUtils.getDoctorClinic()) {
      this.$globalClinic = appUtils.getDoctorClinic()
    }
    if (appUtils.getGlobalOrg()) {
      this.$globalOrg = appUtils.getGlobalOrg()
      if (this.$globalOrg) {
        this.$orgModel = 'hospital'
        if (this.$globalOrg.is_medical === 1) {
          this.$orgModel = 'hospital'
        } else if (this.$globalOrg.is_medical === 2) {
          this.$orgModel = 'business_org'
        }
      } else if (this.$globalClinic) {
        const orgInfo = this.$globalClinic?.organization || {}
        if (orgInfo?.is_medical === 2) {
          this.$orgModel = 'business_org'
        } else {
          this.$orgModel = 'hospital'
        }
      }

      localStorage.setItem('org_model', this.$orgModel)
    }
    if (appUtils.getFeatureList()) {
      this.$featureList = appUtils.getFeatureList()
    }
    if (appUtils.getFeatureWsList()) {
      this.$featureWsList = appUtils.getFeatureWsList()
    }
    if (appUtils.getFeatureOrgList()) {
      this.$featureOrgList = appUtils.getFeatureOrgList()
    }
    if (this.$route.query.token && !this.$route.query?.isResetPwd) {
      this.loginWithToken(this.$route.query.token)

      setTimeout(() => {
        let query = Object.assign({}, this.$route.query)

        delete query.token

        this.$router.replace({ query })
      }, 100)
    }
    if (appUtils.getLocalLang()) {
      this.$lang = appUtils.getLocalLang()
    } else {
      this.$lang = 'vi'
    }
    let noti_id = this.$route?.query?.ntid
    if (noti_id) {
      this.getNotification(noti_id)
    }
    if (appUtils.getAppMode()) {
      this.$appMode = appUtils.getAppMode()
    } else {
      appUtils.setAppMode(APP_MODE.workspace)
      this.$appMode = APP_MODE.workspace
    }
    if (appUtils.getLocalToken()) {
      this.$jwtToken = appUtils.getLocalToken()
      // await this.initializeE3Kit()
    }
  },
  watch: {
    $jwtToken (token) {
      if (!token) return
      this.initializeE3Kit()
    },
    $lang (lang) {
      this.$i18n.locale = lang
      appUtils.setLocalLang(lang)
    },
    $globalOrg () {
      if (this.$globalOrg) {
        this.$orgModel = 'hospital'
        if (this.$globalOrg.is_medical === 1) {
          this.$orgModel = 'hospital'
        } else if (this.$globalOrg.is_medical === 2) {
          this.$orgModel = 'business_org'
        }

        localStorage.setItem('org_model', this.$orgModel)
      }
    },
    $globalClinic () {
      const orgInfo = this.$globalClinic?.organization || {}

      if (orgInfo?.is_medical === 2) {
        this.$orgModel = 'business_org'
      } else {
        this.$orgModel = 'hospital'
      }

      localStorage.setItem('org_model', this.$orgModel)
    },
    $route: {
      deep: true,
      handler (to, from) {
        if (to.meta.requiredInfo && !appUtils.finishInfomation()) {
          let redirect = {}
          if (to.name !== 'HomePage') {
            redirect = { redirect: to.path }
          }
          // if (to.name !== 'Infomation') {
          //   this.$toast.open({
          //     message: 'Vui lòng cập nhật thông tin cá nhân',
          //     type: 'success'
          //   })
          // }
          this.$router.push({ name: 'Infomation', query: redirect }).catch(e => {
            console.log(e)
          })
        }
        if (to.meta.requiredWs && !this.$clinicId) {
          let redirect = {}
          if (to.name !== 'HomePage') {
            redirect = { redirect: to.path }
          }
          this.$toast.open({
            // eslint-disable-next-line standard/computed-property-even-spacing
            message: this.$t('workspace_setting.lbl_need_ws_msg')[this.$orgModel],
            type: 'success'
          })
          this.$router.push({ name: 'DontHaveClinic', query: redirect }).catch(e => {
            console.log(e)
          })
        }
      }
    }
  },
  mounted () {
    let self = this
    // M.AutoInit()

    window.$store = this.$store
    window.$uploadPacsFile = this.onUploadPacs
    if (this.$route.meta.requiredInfo && !appUtils.finishInfomation() && this.$route.name !== 'Infomation') {
      let redirect = {}
      if (this.$route.name !== 'HomePage') {
        redirect = { redirect: this.$route.path }
      }
      // this.$toast.open({
      //   message: 'Vui lòng cập nhật thông tin cá nhân',
      //   type: 'success'
      // })
      this.$router.push({ name: 'Infomation', query: redirect }).catch(e => {})
    }
    if (this.$user) {
      this.onConnectSocket()
      this.visit()
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        // console.log('Service Worker and Push is supported')

        const firebaseMessageSW = `firebase-messaging-sw.js?v=${Date.now()}`
        navigator.serviceWorker
          .register(firebaseMessageSW, {
            useCache: false
          })
          .catch(function (error) {
            console.error('Service Worker Error', error)
          })
          .then(subscription => {
            var firebaseConfig = {
              apiKey: 'AIzaSyDvMMgJWMS8vgtfslZJ56byo3akYbfupZM',
              authDomain: 'hodo-46abc.firebaseapp.com',
              databaseURL: 'https://hodo-46abc.firebaseio.com',
              projectId: 'hodo-46abc',
              storageBucket: 'hodo-46abc.appspot.com',
              messagingSenderId: '923239105015',
              appId: '1:923239105015:web:c8958b39259abffa15eef2',
              measurementId: 'G-Y47WD5M5E1'
            }

            firebase.initializeApp(firebaseConfig)
            const messaging = firebase.messaging()
            messaging
              .getToken({ vapidKey: HodoConfig.firebaseKey })
              .then(async currentToken => {
                if (currentToken) {
                  let params = {
                    device_type: 'web',
                    device_token: currentToken
                  }
                  self.$fcmToken = currentToken
                  appUtils.setFirebaseAccessToken(currentToken)
                  await self.$rf.getRequest('AuthRequest').signUpDevice(params)

                  const pushToken = currentToken
                  const pushProviderType = 'firebase'
                  const userId = this.$user.id.toString()
                  const pushProviderName = 'hodo-notification'

                  const deviceData = await this.$chatClient.addDevice(pushToken, pushProviderType, userId, pushProviderName)
                  console.log(deviceData)
                } else {
                  // Show permission request UI
                  console.log('No registration token available. Request permission to generate one.')
                  // ...
                }
              })
              .catch(() => {
                // console.log('An error occurred while retrieving token. ', err)
                // ...
              })
          })
          .then(() => {
            self.receiveMessage()
          })
      } else {
        console.warn('Push messaging is not supported')
      }
    }
  },
  methods: {
    ...mapActions('app', ['initializeE3Kit']),
    handleOpenApp () {
      const self = this
      try {
        if (!self.isMobile) return
        // window.location = 'hodopatient://'
        const clickedAt = +new Date()
        setTimeout(function () {
          if (+new Date() - clickedAt < 2000) {
            self.showInstallApp = true
            // self.onClickOpenApp()
          }
        }, 300)
      } catch (error) {
        console.log(error)
      }
    },
    onClickOpenApp () {
      window.open('https://hodo.app/#/bsi', '_blank')
    },
    handleCloseInstallApp () {
      this.$refs.InstallApp.remove()
    },
    async loginWithToken (token) {
      if (token) {
        appUtils.setLocalToken(token)
        await this.setLocalUserInfo().then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 300)
        })
      }
    },
    async setLocalUserInfo () {
      let resp = await this.$rf.getRequest('AuthRequest').me()
      if (resp) {
        appUtils.setLocalUser(resp.data)
      }
      return resp
    },
    async onConnectSocket () {
      // Have this in case you stop running your laravel echo server
      if (typeof io !== 'undefined') {
        if (appUtils.getLocalToken()) {
          // let user = this.$user
          this.$echo = new Echo({
            broadcaster: 'socket.io',
            host: process.env.VUE_APP_ECHO_URL,
            auth: {
              headers: {
                Authorization: `Bearer ${appUtils.getLocalToken()}`
              }
            }
          })
          this.$conectedEcho = true
          await this.$echo.connector.socket.on('connect', () => {
            // console.log('Connected to Echo Server')
          })

          // this.$echo
          //   .channel('tv_mode.16.189.4')

          //   .listen('ApptEvent', data => {
          //     console.log('Received data: ', data)
          //   })
          // console.log('connected echo user ' + user.id)
        }
      }
    },
    async visit () {
      // await this.$rf.getRequest('AuthRequest').visit()
      // alert('vô lần 1')
      // if ('caches' in window) {
      //   caches.match(this.$rf.getRequest('AuthRequest').visit()).then(res => {
      //     caches.open('myvisit').then(async cache => {
      //       cache.add(await this.$rf.getRequest('AuthRequest').visit())
      //     })
      //   })
      // }
      // setInterval(async function () {
      // }, 150000)
      // alert('vo r')
    },
    receiveMessage () {
      let self = this
      const messaging = firebase.messaging()
      messaging.onMessage(function (payload) {
        navigator.serviceWorker.getRegistration().then(function (reg) {
          console.log('[App.vue] Received message ', payload)
          // Customize notification here
          // const {title, body} = payload.data
          // const notificationOptions = {
          //   body,
          //   icon: process.env.VUE_APP_BASE_URL + 'hodologo_256x256.png',
          //   data: payload.data
          // }
          self.showToast(payload.data)
        })
      })
    },
    showToast (notification) {
      let self = this
      try {
        const { title, body, notification_id, conversation_sid, conversation_title, twi_body } = notification
        if (!title && !body && !conversation_title && !twi_body) return
        try {
          if (window.document.getElementById('noti_sound')) {
            window.document.getElementById('noti_sound').play()
          }
        } catch (error) {}
        if (conversation_sid && this.$chattingCvstId && conversation_sid === this.$chattingCvstId) {
          return
        }
        // console.log('cvst p n', conversation_sid, twi_message_id, notification_id)
        console.log(notification)
        self.$toast.default('<p class="mb-1 robo-16-500">' + (title || conversation_title || '') + '</p>' + '<div class="toast-push-notify">' + (body || twi_body || '') + '<div>', {
          position: 'bottom-right',
          duration: 5000,
          onClick () {
            self.getNotification(notification_id)
            if (notification.class === 'App\\\\Circles\\\\IndicationResult') {
              const patientVisitId = parseInt(notification.patient_visit_id)
              self.$router.push({
                name: 'PatientVisitDetail',
                params: { id: patientVisitId }
              })
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    onUploadPacs (access, file, type, emr_indication_result_id) {
      if (!access || !file) return

      const self = this
      // let uploadingInfo = {
      //   id: this.makeid() + window.moment().unix(),
      //   name: options.nameFiles?.description
      //     ? options.nameFiles?.description
      //     : file.name,
      //   value: 0,
      //   status: 0
      // };

      // self.$multiUploading.push(uploadingInfo);

      let bucket = null
      const region = 'ap-southeast-1'
      const IdentityPoolId = 'ap-southeast-1:ff53cfbf-2595-4ade-83b8-43e3ad25f279'
      const bucketName = 'hodo-pacdicom'
      const prefix = 'pacdicom'
      let Aws = window.AWS
      Aws.config.region = region // 1. Enter your region
      Aws.config.credentials = new Aws.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId // 2. Enter your identity pool
      })
      Aws.config.credentials.get(function (err) {
        if (err) alert(err)
        // console.log(Aws.config.credentials);
      })

      this.s3Files = []

      bucket = new Aws.S3({
        params: {
          Bucket: bucketName
        }
      })

      if (file) {
        const objKey = prefix + '/mdt_disease/' + access.accession + '.' + file.name.split('.').pop()
        const params = {
          Key: objKey,
          ContentType: file.type,
          Body: file
          // ACL: 'public-read'
        }
        this.progress.show = true
        this.progress.title = 'Đang tải lên Ảnh DICOM'
        this.progress.name = file.name
        self.$uploading = true
        bucket
          .upload(params)
          .on('httpUploadProgress', function (evt) {
            var uploaded = Math.round((evt.loaded / evt.total) * 100)
            // console.log(`File uploaded: ${uploaded}%`)
            self.progress.value = uploaded
          })
          .send(function (err, data) {
            if (err) {
              // an error occurred, handle the error
              console.log('upload failed', err, err.stack)
              return
            }
            self.pacUpdate(data.Key, access).then(async () => {
              if (emr_indication_result_id) {
                await self
                  .postEmrIndicationResultFile({
                    emr_indication_result_id: emr_indication_result_id,
                    type: type,
                    name: file.name,
                    file_name: data.Key,
                    pacs_id: access.id
                  })
                  .finally(() => {
                    setTimeout(() => {
                      self.$refresh++
                    }, 900)
                  })
              }

              self.progress = {
                show: false,
                value: 0,
                name: '',
                title: ''
              }
              self.$uploading = false
            })
          })
      } else {
        alert('Chưa chọn file')
      }
    },
    async pacUpdate (url, access) {
      const self = this
      const params = {
        files: url
      }
      await self.$rf.getRequest('DoctorRequest').updatePacs(access.id, params)
    },
    async postEmrIndicationResultFile (options) {
      const self = this
      const { emr_indication_result_id, ...newOptions } = options
      self.s3Files.push(newOptions)

      let res = await self.$rf
        .getRequest('DoctorRequest')
        .postEmrIndicationResultFile(emr_indication_result_id, {
          files: self.s3Files
        })
        .then(r => {
          return r.data
        })
        .catch(error => {
          console.log('error', error)
        })
      return res
    }
  }
}
</script>

<style scoped>
.install-app {
  transform: translateY(-100%);
}

.install-app.open {
  transform: translateY(0%);
  transition-delay: 500ms;
}

.modal--border {
  border: 1px solid #ccc;
}

.fc-daygrid-event {
  cursor: pointer;
}

.fa-5 {
  font-size: 8em;
}

.btn-block {
  display: block;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

.p-all {
  padding: 16px 8px;
}

.m-a {
  margin: 0 auto;
}

.upload--btn {
  width: 170px;
  height: 170px;
  margin: 0 auto;
  background-color: #f2f2f2;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-radius: 10px;
}

/*.nav-pills .nav-link.active {*/
/*background-color: #7333AE;*/
/*color: #FFF*/
/*}*/

.nav-pills .nav-item {
  margin-right: 2.5px;
  margin-left: 2.5px;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f6f8fb !important;
}
</style>
